import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        name: 'index',
        path: '/',
        component: () => import('./index'),
        meta: {
            title: '首页',
        },
    },
    {
        name: 'xinyongdai',
        path: '/xyd',
        component: () => import('./xinyongdai'),
        meta: {
            title: '信用贷',
        },
    },
    {
        name: 'shuipiaodai',
        path: '/spd',
        component: () => import('./shuipiaodai'),
        meta: {
            title: '税票贷',
        },
    },
    {
        name: 'jingyingdai',
        path: '/jyd',
        component: () => import('./jingyingdai'),
        meta: {
            title: '经营贷',
        },
    },
    {
        name: 'gongjijindai',
        path: '/gjjd',
        component: () => import('./gongjijindai'),
        meta: {
            title: '公积金贷',
        },
    },
    {
        name: 'fangdidai',
        path: '/fdd',
        component: () => import('./fangdidai'),
        meta: {
            title: '房抵贷',
        },
    },
    {
        name: 'chedidai',
        path: '/cdd',
        component: () => import('./chedidai'),
        meta: {
            title: '车抵贷',
        },
    },
];

const router = createRouter({
    routes,
    history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export { router };